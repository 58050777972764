import React, { useEffect } from "react";

const MessageForm = ({
  template,
  setMessage,
  setLocalMessage,
  localMessage,
  requestError,
  setRequestError,
}) => {
  useEffect(() => {
    if (template && template.message !== undefined) {
      setMessage(template.message);
      setLocalMessage(template.message);
      setRequestError(null)
    }
  }, [template.message]);

  const handleChange = (event) => {
    setLocalMessage(event.target.value);
    setRequestError(null)
  };

  return (
    <div className="message-form">
      <p className="message-form_message">Message</p>
      <textarea
        className={`message-form_text ${requestError ? "error" : ""}`}
        value={localMessage}
        onChange={handleChange}
        placeholder="Put the message here..."
      />
    </div>
  );
};

export default MessageForm;