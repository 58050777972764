import React, { useState, useEffect } from "react";
import MessageForm from "../messageForm/messageForm";
import OutputBox from "../outputBox/outputBox";
import TemplateForm from "../templateForm/templateForm";
import useUpdateTemplate from "../../hooks/useUpdateTemplate";
import useParseRequest from "../../hooks/useParseRequest";

const TemplateBox = ({ template }) => {
  const [templateText, setTemplateText] = useState(template.template);
  const [localTemplateText, setLocalTemplateText] = useState("");
  const {
    updateTemplate,
    loading: updateLoading,
    error: updateError,
  } = useUpdateTemplate(template, localTemplateText);
  const {
    sendRequest,
    data,
    setData,
    loading: parseLoading,
    error: parseError,
  } = useParseRequest();
  const [message, setMessage] = useState("");
  const [localMessage, setLocalMessage] = useState("");
  const [requestError, setRequestError] = useState(null);

  useEffect(() => {
    if (template) {
      setTemplateText(template.template);
      setMessage(template.message || "");
      setLocalMessage(template.message || "");
      setData(null);
      setRequestError(null);
    }
  }, [template]);

  useEffect(() => {
    if (parseError) {
      setRequestError(parseError);
    } else if (data) {
      setRequestError(null);
    }
  }, [parseError, data]);

  const handleSave = async () => {
    try {
      await updateTemplate(template.template_id, templateText);
    } catch (error) {
      console.error("Error updating template:", error);
    }
  };

  const handleApply = () => {
    const requestBody = {
      template_id: template.template_id,
      template_name: template.template_name,
      template: templateText,
      message: localMessage,
    };
    sendRequest(requestBody);
  };

  return (
    <div className="template-page">
      <div className="template-page_block">
        <p className="template-page_p">{template.template_name}</p>
      </div>
      <div className="template-box">
        <TemplateForm
          localTemplateText={localTemplateText}
          setLocalTemplateText={setLocalTemplateText}
          template={template}
          setTemplateText={setTemplateText}
          handleSave={handleSave}
          loading={updateLoading}
          requestError={requestError}
          setRequestError={setRequestError}
        />
        <MessageForm
          template={template}
          message={message}
          setMessage={setMessage}
          setLocalMessage={setLocalMessage}
          localMessage={localMessage}
          requestError={requestError}
          setRequestError={setRequestError}
        />
        <OutputBox template={template} onApply={handleApply} data={data} />
      </div>
    </div>
  );
};

export default TemplateBox;