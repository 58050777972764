import React, { useEffect } from "react";

const OutputBox = ({ onApply, loading, error, data }) => {
  return (
    <div className="message-form">
      <p className="message-form_message">Output</p>
      <div
        className="message-form_text output"
        style={{ whiteSpace: "pre-wrap" }}
      >
        {data && (
          <pre className="output">
            {JSON.stringify(data.parsed_result, null, 2)}
          </pre>
        )}
      </div>
      <button
        className="output-page_button"
        style={{ marginTop: "20px" }}
        onClick={onApply}
        disabled={loading}
      >
        Apply
      </button>
    </div>
  );
};

export default OutputBox;
